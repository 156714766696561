<template>
  <div class="ClippingList m-t-m">
    <load-more
      ref="clippings"
      :endpoint="clippingsEndpoint"
      width="column  is-flex  is-12-mobile is-6-tablet is-4-desktop"
    >
      <template slot="item" slot-scope="props">
        <ClippingListItem
          :clipping="props.item"
          @removed="$refs.clippings.removeItem"
        />
      </template>
    </load-more>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClippingListItem from '@/components/clippings/ClippingListItem'

export default {
  components: {
    ClippingListItem
  },

  computed: {
    ...mapGetters(['filtersQuery']),

    clippingsEndpoint () {
      let relationId = this.$route.params.relationId
      const activeBrandId = this.activeBrandId
      return `clippings?include=country&relation=${relationId}&brands[]=${activeBrandId}`
    }
  }
}
</script>
